<template>
  <div id="app">
    <div class="video-background">
      <video autoplay muted loop playsinline>
        <source src="bg.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="content">
      <img src="/title.png" alt="title" class="title-image" />
    <!-- 把标题图片放进conent里面是为了和文字按钮居中 -->
     
      <h1 class="title hidden">LISTEN</h1>
         <!-- 加hidden是为了隐藏标题 -->

      <p class="text">
        Pepepa the Frog is an adorable pink little frog who happily lives with her friends.
        Pepepa loves playing games, dressing up, going on vacations, and joyfully hopping
        round in her cozy mud puddle.
        Besides these, she enjoys exploring new places.
        Though she sometimes encounters little troubles, she always manages to
        turn them around, bringing delightful surprises to everyone.
      </p>

      <div class="additional-text" @click="copyText">
        <span>CA:</span><span>8XKY1EqaUX6tNXM2srz95rWdX8GqKdQVukrxKqUqpump</span>
      </div>

      <button class="button" onclick="window.location.href='https://pump.fun/8XKY1EqaUX6tNXM2srz95rWdX8GqKdQVukrxKqUqpump'">
        <span class="buy-text">BUY</span>
      </button>
      
    </div>
    <div class="social-icons">
      <a href="https://x.com/pepepa_sol" target="_blank">
        <img src="/icon_tw@3x.png" alt="Twitter" />
      </a>
      <a href="https://t.me/pepepa_sol" target="_blank">
        <img src="/icon_telg@3x.png" alt="Telegram" />
      </a>
    </div>
   
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    copyText() {
      const textToCopy = '8XKY1EqaUX6tNXM2srz95rWdX8GqKdQVukrxKqUqpump'; // 获取需要复制的文本
      navigator.clipboard.writeText(textToCopy) // 使用 Clipboard API 复制文本
        .then(() => {
          console.log('Text copied successfully!');
        })
        .catch(err => {
          console.error('Error copying text: ', err);
        });
    }
  }
}
</script>

<style>
/* 导入字体 */
@font-face {
  font-family: 'DynaPuff-Regular';
  src: url('@/assets/fonts/DynaPuff-Regular.ttf') format('truetype');
}

/* 全局设置字体 */
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: 'DynaPuff-Regular';
}

#app {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hidden {
  display: none;
}
/* 这里是隐藏了标题 */

.content {
  position: absolute;
  margin-top: 25%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.additional-text {
  font-size: 1.5em;
  color: #F4B7E8;
  cursor: pointer;
  margin-top: -30px; /* 向上移动 additional-text */
}

.additional-text:hover span:last-child {
  text-decoration: underline;
}

.title {
  font-size: 10em;
  margin-bottom: 20px;
  color: black;
}

.text {
  font-size: 1em;
  max-width: 650px;
  overflow-wrap: break-word;
  line-height: 2.2;
  margin-bottom: 70px;
}

.button {
  margin-top: 35px;
  padding: 11px 65px;
  font-size: 2em;
  color: black;
  font-family: 'DynaPuff-Regular';
  background-color: #FFFFFF;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  transition: transform 0.3s, border-color 0.3s;
  z-index: 2;
  display: flex;
  position: relative; /* 使得伪类可以相对于按钮进行定位 */
}

.buy-text {
  margin-top: 0px; /* 调整 BUY 文字的上移量 */
  font-size: 1.8em;
  color: #3579BE;
}

.button:hover .buy-text {
  color: #F4B7E8; /* 鼠标悬停时的文字颜色 */
}

.button:hover {
  transform: scale(1.1);
  background-color: #3579BE; /* 鼠标悬停时的背景颜色 */
  border-color: transparent; /* 鼠标悬停时去掉边框 */
}

.social-icons {
  position: absolute;
  bottom: 5.5%;
  margin-left: 25%;
  display: flex; /* 使用 flex 布局 */
}

.social-icons a {
  display: flex; /* 使图标居中 */
  align-items: center; /* 使图标垂直居中 */
  justify-content: center; /* 使图标水平居中 */
  width: 70px; /* 设置图标容器宽度 */
  height: 70px; /* 设置图标容器高度 */
  background-color: #3579BE; /* 背景颜色和不透明度 */
  border: 3.5px solid white; /* 添加白色描边 */
  border-radius: 50%; /* 圆形背景 */
  margin-left: 50px; /* 间距 */
  transition: background-color 0.3s, transform 0.3s; /* 加入背景颜色变化过渡 */
}

.social-icons a:first-child {
  margin-left: 0; /* 第一个图标没有左间距 */
}

.social-icons img {
  width: 26px; /* 缩小图标大小 */
  height: 26px; /* 缩小图标大小 */
}

.social-icons a:hover {
  background-color: #DA88CA; /* 鼠标悬停时增加不透明度 */
  transform: scale(1.1);
}

.title-image {
  position: absolute;
  margin-top: -35%;
  width: auto;
  height: 210px;
  z-index: 1;
}
</style>
